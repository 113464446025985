<template>
  <div v-loading="loading" class="school">
    <div class="filter">
      <div class="select">
        <!-- <div class="filter-item">
          选择学校：
          <a-select
            v-model="search.schoolId"
            placeholder="全部学校"
            not-found-content="暂无数据"
            style="width: 180px"
          >
            <a-select-option value="lucy"> lucy </a-select-option>
          </a-select>
        </div> -->
        <div class="filter-item">
          学段：
          <a-select
            v-model="search.level"
            not-found-content="暂无数据"
            placeholder="全部学段"
            style="width: 120px"
            allow-clear
            @change="filterChange"
          >
            <a-select-option
              v-for="(item, index) in levelOptions"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <!-- <div class="filter-item">
          科目：
          <a-select
            v-model="search.subjectId"
            placeholder="全部科目"
            style="width: 120px"
            not-found-content="暂无数据"
            @change="filterChange"
          >
            <a-select-option
              v-for="(item, index) in subjectList"
              :key="index"
              :value="item.subjectId"
            >
              {{ item.subjectName }}
            </a-select-option>
          </a-select>
        </div> -->
      </div>
      <a-input
        v-model="search.keyword"
        placeholder="请输入学校名称"
        style="width: 180px"
        @input="filterChange"
      >
      </a-input>
    </div>
    <div class="operate">
      <div class="title">
        学校列表
        <span v-if="selectedRows.length" class="choose"
          >已选择<span class="num">{{ selectedRows.length }}</span
          >条数据</span
        >
      </div>
      <div class="btn">
        <a-space>
          <a-button v-if="userInfo.roleId == 1" type="primary" @click="add"
            ><a-icon type="plus-circle" />添加</a-button
          >
          <a-button
            v-if="userInfo.roleId == 1"
            type="primary"
            @click="importSchool"
            ><img
              src="@/assets/uExam/icon_import.png"
              style="width: 12px; margin-right: 6px"
              alt=""
            />导入</a-button
          >
          <a-button type="primary" @click="exportFile"
            ><img
              src="@/assets/uExam/icon_export.png"
              style="width: 12px; margin-right: 6px"
              alt=""
            />导出</a-button
          >
          <a-button v-if="userInfo.roleId == 1" type="danger" @click="batch"
            ><img
              src="@/assets/uExam/icon-del.png"
              style="width: 12px; margin-right: 6px"
              alt=""
            />批量删除</a-button
          >
        </a-space>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :row-selection="rowSelection"
      :row-key="(record, index) => record.schoolId"
      bordered
      :locale="locale"
      :pagination="{
        total: total,
        pageSize: search.size,
        current: search.current,
        showSizeChanger: true,
        showTotal: (total) => `共 ${total}条`,
      }"
      @change="tablePaginationChange"
    >
      <template slot="action" slot-scope="text, record">
        <a-button type="link" @click="edit(record)"> 修改</a-button>
        <a-button
          v-if="userInfo.roleId == 1"
          type="link"
          style="color: #f57574"
          @click="del(record)"
        >
          删除</a-button
        >
      </template>
    </a-table>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :width="width4Title[title]"
      centered
      :ok-text="title === '导入学校' ? '导入' : '确定'"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <template v-if="title === '批量删除'">
        <div class="text">
          此操作将永久删除 <span>{{ selectedRows.length }}</span> 条学校信息！
        </div>
        <el-input
          v-model="notInput"
          style="width: 0; height: 0; overflow: hidden"
          placeholder="请输入内容"
        ></el-input>
        <div class="text">
          验证登录密码以继续
          <a-input-password
            v-model="password"
            autocomplete="new-password"
            style="width: 180px; margin-left: 20px"
          ></a-input-password>
        </div>
        <div style="text-align: center">此操作不可撤销</div>
      </template>
      <template v-if="title === '添加学校'">
        <addSchool :key="transferkey" ref="addSchool" :school-ids="schoolIds" />
      </template>
      <template v-if="title === '修改学校'">
        <a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
          <a-form-model-item label="学校名称">{{
            form.name
          }}</a-form-model-item>
          <a-form-model-item label="学段">
            <div class="tags">
              <div
                v-for="(item, index) in form.levels"
                :key="index"
                class="tag"
              >
                {{ item }}
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="学校代码">
            <a-input v-model="form.code" placeholder="请输入学校代码"></a-input>
          </a-form-model-item>
        </a-form-model>
      </template>
      <template v-if="title === '导入学校'">
        <ImportUpload
          :key="uploadKey"
          :file-model="{
            name: '学校模板.xls',
            url: 'https://static.wtjy.com/template/%E5%9F%BA%E7%A1%80%E7%AE%A1%E7%90%86-%E5%AF%BC%E5%85%A5%E5%AD%A6%E6%A0%A1%E6%A8%A1%E6%9D%BF.xls',
          }"
          @change="uploadChange"
        >
          <template slot="tips">
            <div class="tips">
              <div class="tips-title">【提示】</div>
              <div>1、学校名称需要和提供到系统中的一致。</div>
            </div>
          </template>
        </ImportUpload>
      </template>
      <template v-if="title === '导出学校'">
        <div class="tips">
          <div class="tips-title">【提示】</div>
          <div>1、筛选范围支持多选。</div>
        </div>
        <div class="row">
          学段：
          <a-select
            v-model="level"
            not-found-content="暂无数据"
            placeholder="全部学段"
            style="width: 300px"
            allow-clear
            mode="multiple"
          >
            <a-select-option
              v-for="(item, index) in levelOptions"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import noData from "@/components/noData.vue";
import addSchool from "../components/addSchool.vue";
import { getStore } from "@/core/util/store";
import { levelOptions } from "@/core/util/constdata";
import ImportUpload from "@/components/ImportUpload.vue";
import { encryption } from "@/core/util/util";
import {
  getSchoolUnionById,
  editSchoolUnderUnion,
  getSchoolIdByUnionId,
  editSubschool,
  delSubSchool,
  importSubschool,
  exportSubSchool,
} from "@/core/api/newOther/union";
export default {
  name: "School",
  components: {
    addSchool,
    ImportUpload,
  },
  data() {
    const schoolSubjects = getStore({ name: "schoolSubjectList" });
    const userInfo = getStore({ name: "userInfo" });
    return {
      transferkey: Math.random(),
      levelOptions: levelOptions(),
      userInfo: { ...userInfo },
      level: [],
      search: {
        schoolId: undefined,
        subjectId: undefined,
        level: undefined,
        name: undefined,
        size: 20,
        current: 1,
      },
      password: undefined,
      notInput: "",
      form: {},
      subjectList: [...schoolSubjects],
      selectedRows: [],
      width4Title: {
        添加学校: "35%",
        修改学校: "25%",
        删除学校: "35%",
        导入学校: "35%",
        批量删除: "390px",
      },
      confirmLoading: false,
      loading: false,
      tableData: [],
      total: 0,
      locale: {
        emptyText: <noData message="暂无学校信息，请添加学校信息" />,
      },
      columns: [
        {
          title: "序号",
          align: "center",
          width: 80,
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "学校全称",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "学段",
          dataIndex: "period",
          align: "center",
          customRender: (text, record) => {
            if (record.period) {
              // 2024/7/8 有人提出 学校没展示出自己的学段 肖龙 肖世刚提出
              const levelList = [
                {
                  value: "1",
                  label: "小学",
                },
                {
                  value: "2",
                  label: "初中",
                },
                {
                  value: "3",
                  label: "高中",
                },
                {
                  value: "4",
                  label: "大学",
                },
              ];
              let levels = record.period.split(",");
              let levelLabelArr = levels.map((item) => {
                let levelItem = levelList.find((it) => it.value == item);
                return (levelItem && levelItem.label) || "";
              });
              levelLabelArr = levelLabelArr.filter((item) => item);
              return levelLabelArr.join(",");
            }
          },
        },
        {
          title: "学校代码",
          dataIndex: "code",
          align: "center",
          width: 120,
        },

        // {
        //   title: "联系人",
        //   dataIndex: "contacts",
        //   align: "center",
        // },
        // {
        //   title: "联系方式",
        //   dataIndex: "phone",
        //   align: "center",
        // },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          align: "center",
          width: 180,
        },
      ],
      title: "",
      visible: false,
      schoolIds: [],
      originSchools: [],
      uploadKey: Math.random(),
      fileList: null,
    };
  },
  computed: {
    rowSelection() {
      return {
        onSelect: (record, selected, selectedRows) => {
          this.selectedRows = selectedRows;
        },
        onSelectAll: (selected, selectedRows) => {
          this.selectedRows = selectedRows;
        },
      };
    },
  },
  created() {
    this.getData();
  },
  methods: {
    exportFile() {
      this.title = "导出学校";
      this.visible = true;
    },
    uploadChange({ fileList }) {
      if (fileList.length) {
        this.fileList = fileList[0];
      } else {
        this.fileList = [];
      }
    },

    edit(record) {
      this.form = { ...record };
      if (record.period) {
        let levels = record.period.split(",");
        let levelLabelArr = levels.map((item) => {
          let levelItem = this.levelOptions.find((it) => it.value == item);
          return (levelItem && levelItem.label) || "";
        });
        levelLabelArr = levelLabelArr.filter((item) => item);
        this.form.levels = levelLabelArr;
      }
      this.title = "修改学校";
      this.visible = true;
    },
    del(record) {
      // this.form = { ...record };
      this.$confirm(`此操作将删除 ${record.name},是否继续`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await delSubSchool({
            ids: [record.schoolId],
            schoolId: getStore({ name: "school_id" }),
          });
          this.$message({
            showClose: true,
            message: "删除成功",
            type: "success",
          });
          if (this.tableData.length === 1 && this.search.current > 1) {
            this.search.current = this.search.current - 1;
          }
          this.getData();
          this.form = {};
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    addSchoolChange(e) {
      this.schoolIds = [...this.schoolIds, ...e.map((item) => item.id)];
    },
    clearAllSchool() {
      this.schoolIds = [...this.originSchools];
    },
    filterChange() {
      this.search.current = 1;
      this.getData();
    },
    async getData() {
      this.loading = true;
      try {
        const res = await getSchoolUnionById({ ...this.search });
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
        this.loading = false;
        // this.getHaschool();
      } catch {
        this.loading = false;
      }
    },
    batch() {
      this.visible = true;
      this.title = "批量删除";
    },
    handleCancel() {
      this.visible = false;
    },
    async batchDelSubmit() {
      if (this.password) {
        const user = encryption({
          data: {
            randomStr: "blockPuzzle",
            code: "xxx",
            password: this.password,
          },
          key: "pigxpigxpigxpigx",
          param: ["password"],
        });
        await delSubSchool({
          ids: this.selectedRows.map((item) => item.schoolId),
          password: user.password,
          schoolId: getStore({ name: "school_id" }),
        });
        this.$message({
          showClose: true,
          message: "删除成功",
          type: "success",
        });
        this.password = undefined;
        this.visible = false;
        this.search.current = 1;
        this.getData();
        this.selectedRows = [];
      } else {
        this.$message({
          type: "warning",
          message: "请输入登录密码",
          showClose: true,
        });
      }
    },
    handleOk() {
      const funcObj = {
        添加学校: this.addSchoolSubmit,
        修改学校: this.editSubmit,
        批量删除: this.batchDelSubmit,
        导入学校: this.importSchoolSubmit,
        导出学校: this.exportSchoolSubmit,
      };
      funcObj[this.title]();
    },
    async exportSchoolSubmit() {
      this.confirmLoading = true;
      try {
        const res = await exportSubSchool({ levels: this.level.join(",") });
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: res.data.data.url,
            name: res.data.data.name,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
        this.visible = false;
        this.confirmLoading = false;
      } catch {
        this.confirmLoading = false;
      }
    },

    async importSchoolSubmit() {
      if (this.fileList) {
        let data = new FormData();
        data.append("file", this.fileList.originFileObj);
        this.confirmLoading = true;
        try {
          await importSubschool(data);
          this.visible = false;
          this.$message({
            type: "success",
            message: "提交成功",
            showClose: true,
          });
          this.$alert("提交成功", "提示", {
            confirmButtonText: "确定",
            callback: () => {},
          });
          this.confirmLoading = false;
          this.getData();
        } catch {
          this.confirmLoading = false;
        }
      } else {
        this.$message({
          type: "waring",
          message: "请选择需要导入的文件",
          showClose: true,
        });
      }
    },
    async editSubmit() {
      this.confirmLoading = true;
      try {
        await editSubschool({ ...this.form });
        this.$message({
          type: "success",
          message: "提交成功",
          showClose: true,
        });
        this.getData();
        this.form = {};
        this.confirmLoading = false;
        this.visible = false;
      } catch {
        this.confirmLoading = false;
      }
    },
    async addSchoolSubmit() {
      this.confirmLoading = true;
      let shcools = this.$refs.addSchool.old.filter((item) => item.checked);
      if (shcools.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择学校",
          showClose: true,
        });
        this.confirmLoading = false;
        return false;
      }
      try {
        await editSchoolUnderUnion({
          schoolId: getStore({ name: "school_id" }),
          ids: [...this.schoolIds, ...shcools.map((item) => item.id)],
        });
        this.confirmLoading = false;
        this.$message({
          type: "success",
          message: "提交成功",
          showClose: true,
        });
        this.visible = false;
        this.getData();
      } catch {
        this.confirmLoading = false;
      }
    },
    async add() {
      const res = await getSchoolIdByUnionId({
        id: getStore({ name: "school_id" }),
      });
      this.schoolIds = [...res.data.data];
      this.originSchools = [...res.data.data];
      this.title = "添加学校";
      this.visible = true;
      this.transferkey = Math.random();
    },
    importSchool() {
      this.title = "导入学校";
      this.visible = true;
      this.uploadKey = Math.random();
    },
    tablePaginationChange(val) {
      if (this.search.size !== val.pageSize) {
        this.search.current = 1;
        this.search.size = val.pageSize;
      } else {
        this.search.current = val.current;
      }
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
.school {
  .filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    .select {
      display: flex;
      align-items: center;
      .filter-item {
        margin-right: 18px;
      }
    }
  }
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    .title {
      color: #0a1119;
      font-size: 16px;
      .choose {
        margin-left: 18px;
        font-size: 14px;
        color: #0a1119;
        .num {
          color: #2474ed;
        }
      }
    }
  }
}
.text {
  margin-bottom: 18px;
  font-size: 14px;
  span {
    color: #f57574;
  }
}
.upload {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .uploadTips {
    background: #fff5f0;
    border: 1px dashed #ffb692;
    box-sizing: border-box;
    padding: 10px;
    flex: 1;
    color: #343231;
    font-size: 12px;
    margin-left: 18px;
    .footnote {
      color: #e5611f;
      margin-bottom: 18px;
    }
    .text {
      margin-bottom: 18px;
    }
  }
}
.tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .tag {
    width: 48px;
    height: 32px;
    background: #f5f7fa;
    border-radius: 4px;
    text-align: center;
    line-height: 32px;
  }
  .tag + .tag {
    margin-left: 8px;
  }
}
.tips {
  box-sizing: border-box;
  padding: 10px;
  color: #262829;
  font-size: 12px;
  margin-bottom: 18px;
  background-color: #f3f8ff;
  line-height: 2;
  .tips-title {
    color: #2474ed;
  }
}
::v-deep .ant-form-item {
  margin-bottom: 12px !important;
}
::v-deep .ant-modal-body {
  line-height: 1 !important;
}
</style>
